import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import MainPage from './pages/MainPage';

import '../index.css';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Yrsa";
`;


class App extends React.Component {
  render() {
    return (
      <AppContainer>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Redirect from="*" to="/" />
        </Switch>
      </AppContainer>
    );
  }
}

export default App;
