import React from 'react';
import styled from 'styled-components';

import PersonalPicture from '../../assets/img/henry.jpg';
import LinkedInIcon from '../../assets/img/linkedin.png';
import InstagramIcon from '../../assets/img/instagram.png';

const MonoColorBackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #dfd0c0;
  min-height: 100vh;
  position: relative;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  top: 0; bottom: 0; left: 0; right: 0;
  max-width: 80vw;
`;

const PersonalImage = styled.img`
  width: 70vw;
  max-width: 175px;
  border-radius: 50%;
  border: 2px solid #888;
  margin: 0px auto 0px auto;
`;

const TitleContainer = styled.div`
  margin: 30px auto 30px auto;
  text-align: center;
  font-size: 2.0em;
  border-bottom: 1px solid #000;
`;

const ContentContainer = styled.div`
  margin: 20px auto 30px auto;
  z-index: 98;
  text-align: justify;
  font-size: 1.2em;
  max-width: 800px;
  line-height: 1.5em;
`;

const ContactContainer = styled.div`
  font-size: 1.2em;
  max-width: 800px;
  line-height: 2em;
  text-align: center;
  margin-top: 20px;
`;

const Icon = styled.img`
  width: 30px;
  margin-bottom: -0.5em;
  margin-left: 5px;
  margin-right: 5px;
`;

const AboutPage = () => (
  <MonoColorBackgroundContainer>
    <CenterContainer>
      <TitleContainer> Hello! </TitleContainer>
      <PersonalImage src={PersonalPicture} />
      <ContactContainer>
        henry [at] hcho.ca <br />
        <a href="https://ca.linkedin.com/in/hrcho" rel="noreferrer noopener" target="_blank"><Icon src={LinkedInIcon} /></a>
        <a href="https://www.instagram.com/henryrcho" rel="noreferrer noopener" target="_blank"><Icon src={InstagramIcon} /></a>
      </ContactContainer>
      <ContentContainer>
        My name is Henry, and I am currently working at Select Equity Group L.P. as a Data Scientist & Engineer.
        Previously, I conducted research at the Vector Institute and the University of Calgary, studying
        explainable deep learning models and the application of data science on requirements engineering, 
        respectively. I graduated with honors from the University of Toronto with a B.A.Sc. in Engineering Science 
        on April 2020. Outside of work, I follow politics and hockey (go Caps!).
      </ContentContainer>
    </CenterContainer>
  </MonoColorBackgroundContainer>
);

export default AboutPage;
