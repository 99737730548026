import React from 'react';

import LandingPage from '../subpages/LandingPage';
import AboutPage from '../subpages/AboutPage';
import ReactGA from 'react-ga';

class MainPage extends React.Component {
  constructor() {
    super();
    this.initializeReactGa();
    this.state = {
      loaded: false,
    }
  };

  initializeReactGa() {
    ReactGA.initialize('UA-134347496-1');
    ReactGA.pageview('/')
  }

  componentDidMount() {
    this.setState({ 
      loaded: true,
    })
  }

  render() {
    return (
      <>
        <LandingPage />
        <AboutPage />
      </>
    );
  }
}

export default MainPage;
