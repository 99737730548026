import React from 'react';
import styled from 'styled-components';

import BackgroundImage from '../../assets/img/background.jpg';
import Text from '../common/Text';

const ImageBackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url(${BackgroundImage});
  position: relative;
`;

const BackgroundOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  opacity: 0.7;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  top: 0; bottom: 0; left: 0; right: 0;
  margin: auto;
  max-width: 80vw;
  z-index: 2;
`;

const IntroContainer = styled.div`
  padding: 20px;
  border: 1px solid #E0E0E0;
  text-align: center;
`;

const HeaderContainer = styled.div`
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #E0E0E0;
  color: #fff;
  font-size: 3.0em;
`;


const LandingPage = () => (
  <ImageBackgroundContainer>
    <BackgroundOverlay />
    <CenterContainer>
      <IntroContainer>
        <HeaderContainer>
          Henry Cho
        </HeaderContainer>
        <Text size="1.3em" color="#ffffff" content="Data Scientist, Software Engineer" />
      </IntroContainer>
    </CenterContainer>
  </ImageBackgroundContainer>
);

export default LandingPage;
